import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

const Title = styled.div`
  text-align: center;
  font-family: 'Roboto Condensed';
  font-weight: bold;
  font-size: 2.75rem;
  line-height: 1;
  letter-spacing: 0;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 40px;
  @media (max-width: 374px) {
    font-size: 2.5rem;
  }
  @media (max-width: 325px) {
    font-size: 2.25rem;
  }
`

const FeatureImage = styled.div`
  flex: 1;
  max-width: 80%;
  @media (max-width: 450px) {
    max-width: 95%;
    width: 95%;
  }
`

const FeatureImageContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const HomeCard = ({
  image,
  bgImage,
  featureImage,
  titleText,
  reverseText,
  link,
  className,
}) => {
  // Watch out for CSS's stacking order, especially when styling the individual
  // positions! The lowermost image comes last!
  const backgroundFluidImageStack = [
    image.fluid,
    `linear-gradient(to top, rgba(0,0,0,.5), rgba(0,0,0,.5), rgba(0,0,0,.5))`,
  ].reverse()

  return (
    <BackgroundImage
      fluid={backgroundFluidImageStack}
      style={{
        padding: 40,
        borderRadius: '20px',
        overflow: 'hidden',
      }}
    >
      <Title>{titleText}</Title>
      <FeatureImageContainer>
        <FeatureImage>
          {link ? (
            <Link to={link}>
              <Img
                key={featureImage.fluid.src}
                fluid={featureImage.fluid}
                // imgStyle={{ objectFit: 'contain' }}
                alt={featureImage.title}
              />
            </Link>
          ) : (
            <Img
              style={{ flex: 1 }}
              key={featureImage.fluid.src}
              fluid={featureImage.fluid}
              // imgStyle={{ objectFit: 'contain' }}
              alt={featureImage.title}
            />
          )}
        </FeatureImage>
      </FeatureImageContainer>
    </BackgroundImage>
  )
}

const StyledHomeCard = styled(HomeCard)`
  border-radius: 20px;
  background-repeat: no-repeat, no-repeat;
  background-position: center, top center;
  background-size: cover;
  min-height: 200px;
  padding: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  &:nth-child(2) {
    animation-delay: 0.25s;
  }
  &:nth-child(3) {
    animation-delay: 0.5s;
  }
  z-index: 1;
`

export default StyledHomeCard
