import React from 'react'
import { Link, graphql } from 'gatsby'
import { isMobile } from 'react-device-detect'

import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'
import Layout from '../components/layout'
import SEO from '../components/seo'
import HomeCard from '../components/home-card'
import InstagramFeed from '../components/instagram-feed'
import NewsletterSignup from '../components/newsletter-signup'

const HomeCards = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 40px;
  /* @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    margin-top: 15px;
  } */
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 30px;
    margin-top: 15px;
  }
`
const SubContent = styled.div`
  background-color: rgba(27, 28, 29, 0.95);
  box-shadow: 0px 0px 2px #00000080;
  border-radius: 5px;
  @media (max-width: 480px) {
    margin-left: -20px;
    margin-right: -20px;
  }
`
const SubContentInner = styled.div`
  padding: 25px;
`

const FeedsContainer = styled.div`
  display: flex;
  @media (max-width: 1023px) {
    display: block;
  }
`
const InstaFeedContainer = styled.div`
  flex: 4;
  padding-left: 40px;
  @media (max-width: 1023px) {
    margin-top: 25px;
    padding-left: 0;
  }
`
const EventsFeedContainer = styled.div`
  flex: 7;
`
const EventsFeed = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 275px 275px;
  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
    grid-auto-rows: 275px;
  }
`
const EventCard = styled.a`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 15px;
  @media (max-width: 700px) {
    margin-bottom: 10px;
  }
`
const EventTitle = styled.div`
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.3rem;
  font-family: 'Roboto Condensed';
`
const EventTime = styled.div`
  color: #c1c1c1;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.3rem;
  font-family: 'Roboto Condensed';
`
const IndexPage = ({ data }) => (
  <Layout>
    <SEO />
    <HomeCards>
      {data.contentfulHomePage.cards
        .sort((a, b) => a.order - b.order)
        .map(node => (
          <HomeCard
            // className={isMobile ? '' : 'animated fadeInDown'}
            key={node.title}
            titleText={node.title}
            link={node.link}
            reverseText={node.reverseText}
            image={node.image}
            featureImage={node.featureImage}
          />
        ))}
    </HomeCards>
    <SubContent>
      <NewsletterSignup />
      <SubContentInner>
        <FeedsContainer>
          <EventsFeedContainer>
            <EventsFeed>
              {data.contentfulHomePage.events.map(node => {
                const stack = [
                  node.image.fluid,
                  `linear-gradient(180deg, rgba(34, 34, 34, 0) 0%, rgba(34, 34, 34, 1) 100%)`
                ].reverse()
                return (
                  <BackgroundImage
                    key={node.id}
                    fluid={stack}
                    style={{
                      backgroundRepeat: 'no-repeat, no-repeat',
                      backgroundPosition: `bottom center, top center`,
                      backgroundSize: 'cover',
                      display: 'flex'
                    }}
                  >
                    <EventCard href={node.link || '#'}>
                      <EventTitle>{node.title}</EventTitle>
                      <EventTime>{node.time}</EventTime>
                    </EventCard>
                  </BackgroundImage>
                )
              })}
            </EventsFeed>
          </EventsFeedContainer>
          <InstaFeedContainer>
            <InstagramFeed />
          </InstaFeedContainer>
        </FeedsContainer>
      </SubContentInner>
    </SubContent>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query PageQuery {
    contentfulHomePage {
      cards {
        title
        reverseText
        order
        link
        featureImage {
          title
          fluid(maxWidth: 200) {
            src
            srcSet
            sizes
            srcSetWebp
            srcWebp
            base64
            aspectRatio
          }
          fixed(width: 200) {
            src
            srcSet
            srcSetWebp
            srcWebp
            tracedSVG
            aspectRatio
            width
            height
          }
          file {
            url
          }
        }
        image {
          fluid(maxWidth: 500) {
            src
            srcSet
            srcSetWebp
            srcWebp
            base64
            aspectRatio
          }
          file {
            url
          }
        }
      }
      events {
        title
        time
        link
        image {
          fluid(maxWidth: 400) {
            src
            srcSet
            srcSetWebp
            srcWebp
            base64
            aspectRatio
          }
          file {
            url
          }
        }
      }
    }
  }
`
