import React, { useEffect, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import instaLogo from '../images/instagram-logo.svg'

const FollowButton = styled.a`
  background: #1ca0f2 0% 0% no-repeat padding-box;
  border-radius: 99px;
  padding: 8px 10px;
  line-height: 1;
  display: flex;
  align-items: center;
  color: #222;
  font-weight: bold;
  font-size: 0.85rem;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`
const Feed = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr;
`
const InstaLogo = styled.img`
  @media (max-width: 1024px) {
    max-width: 100px;
  }
  @media (max-width: 374px) {
    max-width: 80px;
  }
`
const Handle = styled.span`
  color: #fff;
  font-weight: 100;
  font-size: 1.1rem;
  @media (max-width: 400px) {
    font-size: 0.9rem;
  }
`
const InstagramFeed = () => {
  const [feed, setFeed] = useState([])
  useEffect(() => {
    const load = async () => {
      const result = await axios.get('/.netlify/functions/instagram')
      if (result && result.data && result.data.data) {
        setFeed(
          result.data.data
            .filter(item => item.media_url.indexOf('.mp4') === -1)
            .slice(0, 6)
            .map(item => ({
              link: item.permalink,
              url: item.media_url,
              alt: item.caption
            }))
        )
      }
    }
    load()
  }, [])

  console.log(feed)
  return (
    <div>
      <Header>
        <InstaLogo src={instaLogo} alt="instagram logo" />
        <Handle>@cinderblockbrew</Handle>
        <FollowButton href="https://www.instagram.com/cinderblockbrew/">
          follow
        </FollowButton>
      </Header>
      <Feed>
        {feed.map(f => (
          <a
            key={f.link}
            href={f.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={f.url} alt={f.alt} style={{ width: '100%' }} />
          </a>
        ))}
      </Feed>
    </div>
  )
}

export default InstagramFeed
